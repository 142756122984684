export const skipUrl = (url) => {
  if (!url) return
  const regexp = /^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/
  if (regexp.test(url)) {
    window.open(url, '_self')
  } else {
    window.open(`//${url}`, '_self')
  }
}
/**
 * 阿拉伯数字转中文数字
 * @param Number
 * @returns {string}
 */
export function numberToChinese(num) {
  if (!/^\d*(\.\d*)?$/.test(num)) {
    return num
  }
  const AA = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  const BB = ['', '十', '百', '千', '万', '亿', '点', '']
  var a = ('' + num).replace(/(^0*)/g, '').split('.'),
    k = 0,
    re = ''
  for (let i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0:
        re = BB[7] + re
        break
      case 4:
        if (!new RegExp('0{4}\\d{' + (a[0].length - i - 1) + '}$').test(a[0])) re = BB[4] + re
        break
      case 8:
        re = BB[5] + re
        BB[7] = BB[5]
        k = 0
        break
    }
    if (k % 4 === 2 && a[0].charAt(i + 2) !== 0 && a[0].charAt(i + 1) === 0) re = AA[0] + re
    if (a[0].charAt(i) !== 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re
    k++
  }
  if (a.length > 1) {
    //加上小数部分(如果有小数部分)
    re += BB[6]
    for (let i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)]
  }
  return re
}
export const getObjType = obj => {
  var toString = Object.prototype.toString
  var map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}
/**
 * 对象深拷贝
 */
export const deepClone = data => {
  var type = getObjType(data)
  var obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    return data
  }
  if (type === 'array') {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (var key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}
/**
 * 数字索引转字母 1 -> A   26 -> Z
 * @param num
 * @returns {string}
 */
export function num2Letter(num) {
  const n = parseInt(num)
  if (!isNaN(n) && n > 0 && n < 27) {
    return String.fromCharCode(64 + n)
  }
}
/**
 * 处理标题，去掉标签 和空格符
 *
 */
export function filterStr(str) {
  const re = /<[^>]+>/g
  if (str) {
    let newStr = str.replace(re, '')
    newStr = newStr.replace(/&nbsp;/gi, '')
    return newStr
  }
  return str
}
/**
 * 格式化选择题选项
 * A、=>''
 * A.=>''
 * A．=>''
 * A。=>''
 * (A) =>''
 * （A）=>''
 * @param html
 * @returns {*}
 */
export function formatChoiceOption(html) {
  // return (
  //   html &&
  //   html
  //     .replace(/[\n\r]/g, '')
  //     .replace(/&nbsp;/g, '')
  //     .replace(/([A-Z][\、|\．|.|\。])|[\(|\（][A-Z][\)|\）]/, '')
  // )
  const newHtml = html &&
  html
    .replace(/[\n\r]/g, '')
    .replace(/&nbsp;/g, '')
  const regExp = /([A-Z][、|．|.|。])|[(|（][A-Z][)|）]/
  //去掉所有的标签
  // const matchData1 = newHtml.(re)
  const text = filterStr(newHtml)
  const startData = text ? text.substring(0, 2) : ''
  const searchStart = startData.search(regExp)
  // console.log(searchStart)
  if (newHtml && searchStart > -1) { //确认开头存在
    const suitIndex = newHtml.search(regExp)
    const startHtml = newHtml.substring(0, suitIndex)
    const lastHtml = newHtml.substring(suitIndex + 2)

    return startHtml + lastHtml
  } else {
    return newHtml
  }
}
/**
 * 获取dom 设置页面展示位置
 * @param id
 * @param {Number} type 1：'start' 2:'end'
 */
export function setScrollIntoView(id, type = 1) {
  const dom = document.getElementById(id)
  const block = type === 1 ? 'start' : 'end'
  if (dom) {
    dom.scrollIntoView({
      block: block,
      behavior: 'smooth'
    })
  }
}

export const escape2Html = str => {
  const arrEntities = {
    'lt': '<',
    'gt': '>',
    'nbsp': ' ',
    'amp':
    '&',
    'quot': '"',
    'mdash': '—',
    "ldquo": '“',
    "rdquo": '”',
    "rsquo": '’',
    "lsquo": '‘',
    "permil": '‰',
    "euro": '€',
    "le": '≤',
    "ge": '≥',
    "ne": '≠',
    "middot": '·',
    "trade": '™',
    "\\n": ' '
  }
  return str.replace(/&(lt|gt|nbsp|amp|quot|mdash|ldquo|rdquo|rsquo|lsquo|permil|euro|le|ge|ne|middot|trade);|(\\n)/gi, (all, t, d) => {
    return arrEntities[t] || arrEntities[d]
  })
}

export function formatBytes(size, digit = 2) {
  if (size || size === 0) {
    if (size === 0) return '0 B'
    if (size < 0) return '未知'
    const ratio = 1024,
      unit = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      f = Math.floor(Math.log(size) / Math.log(ratio))
    return parseFloat((size / Math.pow(ratio, f)).toFixed(digit)) + ' ' + unit[f]
  } else {
    return '大小转换失败'
  }
}

const envList = ['dev-edu', 'test', 'pre']
export const env_name = envList.find(v => {
  return location.host.includes(v)
}) || ''

export function toLogin(searchObj) {
  if (window.JSAndroid) {
    window.JSAndroid.notifyOrLogout(JSON.stringify({
      notify: '当前登录已失效',
      logout: true
    }))
  } else {
    const hosts = window.location.host.split('.').reverse()
    const host = hosts.length > 1 ? `${hosts[1]}.${hosts[0]}` : 'huixuecloud.cn'
    const origin = env_name ? `${env_name}login.${host}` : `login.${host}`
    const query = `?redirect_url=${window.location.origin}${window.location.pathname}`
    let search = ''
    for (let x in searchObj) {
      search += (`${x}=${searchObj[x]}` + '&')
    }
    window.location.href = `//${origin}${query}${search.substring(0, search.length - 1)}`
  }
}

/**
 * 对象qs拼接成字符串
 * @param obj 转拼接对象
 */
export function queryString(obj) {
  let str = '?'
  if (Object.keys(obj).length > 0) {
    Object.keys(obj).forEach(val => {
      str = str + val + '=' + obj[val] + '&'
    })
    str = str.slice(0, str.length - 1)
  }
  return str
}

//节流函数
export function throttle(func, wait) {
  let timeout;
  return function (...args) {
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(this, args);
      }, wait);
    }
  };
}