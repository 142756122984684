import request from "@/api/axios";
import qs from "querystring";
import { apiList as api } from "./apiList";

export function getUserToken(params) { // 帐号密码登录
  const grant_type = "password";
  return request({
    url: api.user.getAuthToken,
    method: "post",
    headers: {
      Authorization: "Basic Y29tbW9uOmNvbW1vbg=="
    },
    params: { grant_type },
    data: qs.stringify({
      username: params.userName,
      password: params.password
    })
  });
}
export function getMobileToken(params) { // 手机号验证码登录
  const grant_type = "mobile";
  return request({
    url: api.user.getAuthToken,
    method: "post",
    headers: {
      Authorization: "Basic Y29tbW9uOmNvbW1vbg=="
    },
    params: { grant_type },
    data: qs.stringify({
      mobile: "SMS@" + params.userName,
      code: params.password
    })
  });
}
export function getTokenUser(token) { // 根据token查询用户信息
  return request({
    url: api.user.getTokenUser,
    method: "get",
    noAuth: true,
    headers: {
      Authorization: "Basic Y29tbW9uOmNvbW1vbg=="
    },
    params: {
      clientId: 'common',
      token
    }
  });
}
export function getSendSmsCode(mobile, type) { // 发送手机验证码
  return request({
    url: api.user.getSendSmsCode + mobile + '/' + type,
    method: "get"
  });
}
export function getSendEmailCode(email, type) { // 发送邮箱验证码
  return request({
    url: api.user.getSendEmailCode + email + '/' + type,
    method: "post"
  });
}
export function getVeriSmsCode(mobile, type, code) { // 验证手机验证码
  return request({
    url: api.user.getVeriSmsCode + mobile + '/' + type + '/' + code,
    method: "get"
  });
}
export function getVeriEmailCode(email, type, code) { // 验证邮箱验证码
  return request({
    url: api.user.getVeriEmailCode + email + '/' + type + '/' + code,
    method: "get"
  });
}
export function getEditPassword(data) { // 修改密码
  return request({
    url: api.user.getEditPassword,
    method: "post",
    data
  });
}
export function getAddRegister(mobile) { // 手机号注册帐号
  return request({
    url: api.user.getAddRegister + mobile,
    method: "get"
  });
}
export function getAccountInfo(userId) { // 根据id获取帐号信息
  return request({
    url: api.user.getAccountInfo + userId,
    method: "get"
  });
}
export function getEditPerson(data) {
  return request({
    url: api.user.getEditPerson, // 个人信息完善
    method: "post",
    data
  });
}
export function getUpdateInfo(data) { // 修改帐号信息
  return request({
    url: api.user.getUpdateInfo,
    method: "put",
    data
  });
}
export function getSendSmsCodes(mobile, type) { // 发送手机验证码-登录后的
  return request({
    url: api.user.getSendSmsCodes + mobile + '/' + type,
    method: "get"
  });
}
export function getUserCheckPwd(data) { // 验证帐号密码是否正确
  return request({
    url: api.user.getUserCheckPwd,
    method: "post",
    data
  });
}
export function getCancleledReason(userId) { // 获取帐号能否注销和原因
  return request({
    url: api.user.getCancleledReason + userId,
    method: "get"
  });
}
export function getCancleled(userId) { // 注销个人帐号
  return request({
    url: api.user.getCancleled + userId,
    method: "get"
  });
}
export function getRecallCancleled(userId) { // 注销个人帐号
  return request({
    url: api.user.getRecallCancleled + userId,
    method: "get"
  });
}
export function getSignOut() { // 退出登录
  return request({
    url: api.user.getSignOut,
    method: "delete"
  });
}
export function getInstitution(domainName) { // 根据三级域名获取机构信息
  return request({
    url: api.user.getInstitution + domainName,
    method: "get"
  });
}
export function getUserInfo(userId) { // 根据id获取个人信息
  return request({
    url: api.user.getUserInfo + userId,
    method: "get"
  });
}
export function getUpdatePersonal(data) { // 修改个人信息
  return request({
    url: api.user.getUpdatePersonal,
    method: "put",
    data
  });
}
export function getDictItem(code) { // 获取字典项   学段 period；学科 subject；学制 school_length；年级 grade；
  return request({
    url: api.user.getDictItem + code,
    method: "get"
  });
}
export function getCommonModeration(data) {
  return request({
    url: api.user.getCommonModeration, // 文本内容审核
    method: "post",
    data
  });
}
export function getVipHeader(userId) {
  return request({
    url: api.user.getVipHeader + userId, // 获取会员头像框
    method: "get"
  });
}

export function bindWechat(data) {
  return request({
    url: api.user.bindWechat, // 绑定微信
    method: "post",
    data
  });
}

export function unbindWechat(data) {
  return request({
    url: api.user.unbindWechat, // 解除绑定微信
    method: "post",
    data
  });
}

export function setUserTheme(data) {
  return request({
    url: api.user.setUserTheme, // 设置主题
    method: "post",
    data
  });
}

export function getManagerContacts() {
  return request({
    url: api.user.getManagerContacts, // 获取机构/学校下管理员
    method: "get"
  });
}
export function getAvatarList() {
  return request({
    url: api.user.getAvatarList, //  获取头像列表
    method: "get"
  });
}
