import request from "@/api/axios";
import { apiList as api } from "./apiList";

export function getAreaTree() {
  return request({
    url: api.home.getAreaTree, // 获取省市区树
    method: "get",
  });
}
export function getPlanData(type) {
  return request({
    url: api.home.getPlanData + type, // 目标和任务计划内的数据
    method: "get"
  });
}
export function getSpaAfficheList(categoryCode) {
  return request({
    url: api.home.getSpaAfficheList + categoryCode, // 广告列表
    method: "get",
  });
}
export function getMsgNotifyTip() {
  return request({
    url: api.home.getMsgNotifyTip, // 跑马灯通知
    method: "get",
  });
}
export function getMsgNotifyList(params) {
  return request({
    url: api.home.getMsgNotifyList, // 消息列表
    method: "get",
    params
  });
}
export function getMstListAll(data) {
  return request({
    url: api.home.getMstListAll, // 消息列表
    method: "post",
    data
  });
}
export function getAppMusts() {
  return request({
    url: api.home.getAppMusts, // 必备应用
    method: "get"
  });
}
export function getAppGroup() {
  return request({
    url: api.home.getAppGroup, // 应用分类列表
    method: "get"
  });
}
export function getAppAlias(alias) {
  return request({
    url: api.home.getAppAlias + alias, // 查看应用权限
    method: "get"
  });
}
export function getGroupList(userId, params) {
  return request({
    url: api.home.getGroupList + userId, // 群组列表
    method: "get",
    params
  });
}
export function getMyGroupList(userId) {
  return request({
    url: api.home.getMyGroupList + userId, // 我的群组
    method: "get"
  });
}
export function getGroupQrCode(groupQrCode) {
  return request({
    url: api.home.getGroupQrCode + groupQrCode, // 根据群号查找群组
    method: "get"
  });
}
export function getGroupAdd(data) {
  return request({
    url: api.home.getGroupAdd, // 加入指定群组
    method: "post",
    data
  });
}
export function getGroupAddList(userId, params) {
  return request({
    url: api.home.getGroupAddList + userId, // 已加入的群组
    method: "get",
    params
  });
}
export function getGroupDel(groupId, params) {
  return request({
    url: api.home.getGroupDel + groupId, // 退出指定群组
    method: "put",
    params
  });
}
export function getCourse() {
  return request({
    url: api.home.getCourse,  // 随机获取一个学程
    method: "get",
  });
}
export function getNoAccesses() {
  return request({
    url: api.home.getNoAccesses,  // 应用中心
    method: "get",
  });
}

export function getAppInfo(alias) {
  return request({
    url: api.home.getAppInfo + alias, // 通过别名访问应用信息
    method: "get",
  });
}

export function getBatchUpdate() {
  return request({
    url: api.home.getBatchUpdate,// 消息标记为已读
    method: "get",
  });
}
export function getDefaultSchool() {
  return request({
    url: api.home.getDefaultSchool, // 默认的学校
    method: "get"
  });
}
export function getCurrentWeather(data) {
  return request({
    url: api.home.getCurrentWeather, // 获取当前天气
    method: "post",
    data
  });
}
export function getDeptInfo(institutionId) {
  return request({
    url: api.home.getDeptInfo, // 获取部门信息
    method: "post",
    data: {
      type: 0,
      institutionId
    }
  });
}
export function getClassPhotos(data) {
  return request({
    url: api.home.getClassPhotos, // 获取班级相册
    method: "post",
    data
  });
}
export function getAppList() {
  return request({
    url: api.home.getAppList, // 获取所有应用列表
    method: "get"
  });
}

/**
 * 掌阅APP对接业务  告知服务层用户进了电子图书页面
 */
export function flowIreader() {
  return request({
    url: api.tpi.flowIreader, // '/tpi/reader/read',
    method: 'get'
  })
}

/**
 * 首页数据看板接口 ↓
 */

// 获取学校列表
export function getSchoolList() {
  return request({
    url: api.home.dateReport.getSchoolList,
    method: 'get',
  })
}

// 获取班级类型
export function getClassTypes() {
  return request({
    url: api.home.dateReport.getClassTypes,
    method: 'get',
  })
}

// 获取年级
export function getGrades(params) {
  return request({
    url: api.home.dateReport.getGrades,
    method: 'get',
    params
  })
}

// 获取年级或者学校 新
export function getDeptAuthList(data) {
  return request({
    url: api.home.dateReport.getDeptAuthList,
    method: 'post',
    data
  })
}

// 获取班级
export function getClass(params) {
  return request({
    url: api.home.dateReport.getClass,
    method: 'get',
    params
  })
}

// 课堂统计
export function getRectorWeekStatic(params, type) {
  return request({
    url: api.home.dateReport[type].getWeekStatic,
    method: 'get',
    params
  })
}

// 课堂组织情况
export function getRectorGradeClassroomWeekStatic(params, type) {
  return request({
    url: api.home.dateReport[type].getGradeClassroomWeekStatic,
    method: 'get',
    params
  })
}

export function getAppBubbleByAlias(data) {
  return request({
    url: api.home.getAppBubbleByAlias, // 应用气泡数量获取
    method: "post",
    data
  });
}

export function getAppCategoryBubbleByCategories(params, data) {
  return request({
    url: api.home.getAppCategoryBubbleByCategories, // 应用菜单气泡数量获取
    method: "post",
    params,
    data
  });
}

export function getAppSort(memberType) {
  return request({
    url: api.home.getAppSort + memberType, // 用户下分类应用排序获取
    method: "get",
  });
}

export function sortApp(memberType, data) {
  return request({
    url: api.home.sortApp + memberType, // 用户下分类应用排序设置
    method: "post",
    data
  });
}

export function getAppUsable(appId) {
  return request({
    url: api.home.getAppUsable + appId, // 用户下分类应用排序设置
    method: "get",
  });
}

export function getAllTimeRange(appId) {
  return request({
    url: api.home.getAllTimeRange + appId, // 用户下分类应用排序设置
    method: "get",
  });
}

/**
 * 首页数据看板接口 ↑
 */
