const map = {
  "//localhost:7301/": "organization",
  "//localhost:7302/": "account-workspace",
  "//localhost:7303/": "account-customer",
  "//localhost:7304/": "account-personal",
  "//localhost:7305/": "permission",
  "//localhost:7306/": "app-centre",
  "//localhost:7307/": "app-manage",
  "//localhost:7308/": "source-library",
  "//localhost:7309/": "groups-space",
  "//localhost:7310/": "data-manage",
  "//localhost:7311/": "source-manager",
  "//localhost:7312/": "groups-config",
  "//localhost:7313/": "init-config",
  "//localhost:7314/": "prepare-lesson",
  "//localhost:7315/": "my-goods",
  "//localhost:7316/": "vip-strategy",
  "//localhost:7317/": "orders-manager",
  "//localhost:7318/": "edit-homeweb",
  "//localhost:7319/": "class-photo",
  "//localhost:7320/": "weather",
  "//localhost:7321/": "ks-resource",
  "//localhost:7322/": "external-channel",
  "//localhost:7323/": "manage-channel",
  "//localhost:7324/": "manage-topic",
  "//localhost:7325/": "special-topic",
  "//localhost:7326/": "manage-address",
  "//localhost:7327/": "chatgpt",
  "//localhost:7328/": "manage-activity",
  "//localhost:7329/": "class-setting",
  "//localhost:7330/": "class-interaction",
  "//localhost:7331/": "class-reports",
  "//localhost:7332/": "activity",
  "//localhost:7333/": "learn-centre",
  "//localhost:7334/": "expert-library",
  "//localhost:7335/": "evaluate",
  "//localhost:7336/": "learning-tools",
  "//localhost:7337/": "certificate-editor",
  "//localhost:7338/": "class-manage",
  "//localhost:7339/": "certificate-manage",
  "//localhost:7340/": "evaluate-system-quota",
  "//localhost:7341/": "evaluate-agency-manage",
  "//localhost:7342/": "inspiration-centre",
  "//localhost:7343/": "class-stu-reports",
  "//localhost:7344/": "comprehensive-evaluate",
  "//localhost:7345/": "org-source-manage",
  "//localhost:7346/": "parents-manage",
  "//localhost:7347/": "chat-manage",
  "//localhost:7348/": "educational-info",
  "//localhost:7349/": "chat-qa",
  "//localhost:7350/": "todo-list",
  "//localhost:7351/": "school-source-lib",
  "//localhost:7352/": "class-notice",
  "//localhost:7353/": "spoken-training",
  "//localhost:7354/": "data-view",
  "//localhost:7355/": "typical-exercises",
  "//localhost:7356/": "chat-ai",
  "//localhost:7357/": "cloud-notes",
  "//localhost:7358/": "client-download",
  "//localhost:7359/": "class-portal",
  "//localhost:7360/": "apply-app",
  "//localhost:7361/": "manage-school-group",
  "//localhost:7362/": "manage-parents-lessons",
  "//localhost:7363/": "parents-lessons",
  "//localhost:7364/": "limit-usage-app"
};

export default function hostMap(host) {
  if (process.env.NODE_ENV === "production") {
    const hosts = window.location.host.split(".").reverse();
    const target =
      hosts.length > 1 ? `${hosts[1]}.${hosts[0]}` : "huixuecloud.cn";
    if (window.location.host.includes("main")) {
      return `//${window.location.host.split("main")[0]}${
        map[host]
      }.${target}/`;
    } else {
      return `//${map[host]}.${target}/`;
    }
  }
  return host;
}
