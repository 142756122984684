import { createRouter, createWebHistory } from "vue-router";

const basename = "";

const routes = [
  {
    path: "/",
  },
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "main" */ "@/views/Home.vue"),
  },
  {
    path: "/notice",
    name: "notice",
    component: () =>
      import(/* webpackChunkName: "main" */ "@/views/Notice.vue"),
  },
  {
    path: "/courseDetail",
    name: "courseDetail",
    component: () =>
      import(
        /* webpackChunkName: "main" */ "@/views/sourcePreview/courseDetail/index.vue"
      ),
  },
  {
    path: "/paperPreview",
    name: "paperPreview",
    component: () =>
      import(
        /* webpackChunkName: "main" */ "@/views/sourcePreview/paperDetail/previewPractice/index.vue"
      ),
  },
  {
    path: "/curriculumDetail",
    name: "curriculumDetail",
    component: () =>
      import(
        /* webpackChunkName: "main" */ "@/views/sourcePreview/curriculumDetail/index.vue"
      ),
  },
  {
    path: "/newCurriculumDetail",
    name: "newCurriculumDetail",
    component: () =>
      import(
        /* webpackChunkName: "main" */ "@/views/sourcePreview/newCurriculumDetail/index.vue"
      ),
  },
  {
    path: "/resourceDetail",
    name: "resourceDetail",
    component: () =>
      import(
        /* webpackChunkName: "main" */ "@/views/sourcePreview/resourceDetail/index.vue"
      ),
  },
  {
    path: "/practiceDetail",
    name: "practiceDetail",
    component: () =>
      import(
        /* webpackChunkName: "main" */ "@/views/sourcePreview/practiceDetail/index.vue"
      ),
  },
  {
    path: "/articleDetail",
    name: "articleDetail",
    component: () =>
      import(
        /* webpackChunkName: "main" */ "@/views/sourcePreview/articleDetail/index.vue"
      ),
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: () =>
      import(
        /* webpackChunkName: "main" */ "@/views/sourcePreview/newsDetail/index.vue"
      ),
  },
  {
    path: '/generate-paper', // 特殊标识学科网落地页
    name: 'generate-paper',
    component: () => import(/* webpackChunkName: "main" */ '@/views/xkw/index.vue'),
  },
  {
    path: '/accountInfo',
    name: 'accountInfo',
    component: () => import(/* webpackChunkName: "main" */ '@/views/AccountInfo.vue'),
  },
  {
    path: "/userInfo",
    name: "userInfo",
    component: () =>
      import(/* webpackChunkName: "main" */ "@/views/UserInfo.vue"),
  },
  {
    path: "/spaceInfo",
    name: "spaceInfo",
    component: () =>
        import(/* webpackChunkName: "main" */ "@/views/SpaceInfo.vue"),
  },
  {
    path: "/theme",
    name: "theme",
    component: () => import(/* webpackChunkName: "main" */ "@/views/Theme.vue"),
  },
  {
    path: "/organization",
    name: "organization",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/accountWorkspace",
    name: "accountWorkspace",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/accountCustomer",
    name: "accountCustomer",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/accountPersonal",
    name: "accountPersonal",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/permission",
    name: "permission",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/baseDataManage",
    name: "baseDataManage",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/sourceManage",
    name: "sourceManage",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/groupsSpaceConfig",
    name: "groupsSpaceConfig",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/appManage",
    name: "appManage",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/appCentre",
    name: "appCentre",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/sourceLibrary",
    name: "sourceLibrary",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/groupsSpace",
    name: "groupsSpace",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/initializeConfig",
    name: "initializeConfig",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/prepareLesson",
    name: "prepareLesson",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/myGoods",
    name: "myGoods",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/vipStrategy",
    name: "vipStrategy",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/ordersManager",
    name: "ordersManager",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/editHomeweb",
    name: "editHomeweb",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/classPhoto",
    name: "classPhoto",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/weather",
    name: "weather",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/ksResource",
    name: "ksResource",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/externalChannel",
    name: "externalChannel",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/manageChannel",
    name: "manageChannel",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/manageTopic",
    name: "manageTopic",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/specialTopic",
    name: "specialTopic",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/manageAddress",
    name: "manageAddress",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/chatgpt",
    name: "chatgpt",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/manageActivity",
    name: "manageActivity",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/classSetting",
    name: "classSetting",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/classInteraction",
    name: "classInteraction",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/classReports",
    name: "classReports",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/activity",
    name: "activity",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/learnCentre",
    name: "learnCentre",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/expertLibrary",
    name: "expertLibrary",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/evaluate",
    name: "evaluate",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/learningTools",
    name: "learningTools",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/certificateEditor",
    name: "certificateEditor",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/classManage",
    name: "classManage",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/certificateManage",
    name: "certificateManage",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/evaluateSystemQuota",
    name: "evaluateSystemQuota",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/evaluateAgencyManage",
    name: "evaluateAgencyManage",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/inspirationCentre",
    name: "inspirationCentre",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/classStuReports",
    name: "classStuReports",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/comprehensiveEvaluate",
    name: "comprehensiveEvaluate",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/orgSourceManage",
    name: "orgSourceManage",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/parentsManage",
    name: "parentsManage",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/chatManage",
    name: "chatManage",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/educationalInfo",
    name: "educationalInfo",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/chatQa",
    name: "chatQa",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/todoList",
    name: "todoList",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/schoolSourceLib",
    name: "schoolSourceLib",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/classNotice",
    name: "classNotice",
    component: () =>
      import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/boxiaoTranscript",
    name: "boxiaoTranscript",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoShareFrame",
    name: "boxiaoShareFrame",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiaoShare.vue"),
  },
  {
    path: "/boxiaoAchievementManage",
    name: "boxiaoAchievementManage",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoAchievementTeach",
    name: "boxiaoAchievementTeach",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoAchievementStu",
    name: "boxiaoAchievementStu",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoCurriculaManage",
    name: "boxiaoCurriculaManage",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoCurriculaStu",
    name: "boxiaoCurriculaStu",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoWeekSetting",
    name: "boxiaoWeekSetting",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoArrangingManage",
    name: "boxiaoArrangingManage",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoSchoolArranging",
    name: "boxiaoSchoolArranging",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoArrangingSearch",
    name: "boxiaoArrangingSearch",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoArrangingStu",
    name: "boxiaoArrangingStu",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoQapaperManage",
    name: "boxiaoQapaperManage",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoQapaper",
    name: "boxiaoQapaper",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoElectivesManage",
    name: "boxiaoElectivesManage",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoElectivesStu",
    name: "boxiaoElectivesStu",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoBuildingRoom",
    name: "boxiaoBuildingRoom",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoDataDev",
    name: "boxiaoDataDev",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoEvaTeachManage",
    name: "boxiaoEvaTeachManage",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoEvaTeachReport",
    name: "boxiaoEvaTeachReport",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoEvaTeachStudent",
    name: "boxiaoEvaTeachStudent",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoSendRecieveFile",
    name: "boxiaoSendRecieveFile",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoSendRecieveFileUser",
    name: "boxiaoSendRecieveFileUser",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoListenEvalCourseManage",
    name: "boxiaoListenEvalCourseManage",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoListenEvalCourse",
    name: "boxiaoListenEvalCourse",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoWorkflowManage",
    name: "boxiaoWorkflowManage",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoWorkflowExamine",
    name: "boxiaoWorkflowExamine",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoStuAskForLeaveManage",
    name: "boxiaoStuAskForLeaveManage",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoStuAskForLeaveTeacher",
    name: "boxiaoStuAskForLeaveTeacher",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoStuAskForLeaveStu",
    name: "boxiaoStuAskForLeaveStu",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoKnowledgeAnalyse",
    name: "boxiaoKnowledgeAnalyse",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoTeacherAttendanceManage",
    name: "boxiaoTeacherAttendanceManage",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoTeacherAttendance",
    name: "boxiaoTeacherAttendance",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoStudentPrizeManage",
    name: "boxiaoStudentPrizeManage",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoStudentPrize",
    name: "boxiaoStudentPrize",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoFiveEvaluationManage",
    name: "boxiaoFiveEvaluationManage",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoExamMattersManage",
    name: "boxiaoExamMattersManage",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoMeetApointmentManage",
    name: "boxiaoMeetApointmentManage",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/boxiaoMeetApointment",
    name: "boxiaoMeetApointment",
    component: () => import(/* webpackChunkName: "boxiao" */ "@/views/boxiao.vue"),
  },
  {
    path: "/spokenTraining",
    name: "spokenTraining",
    component: () => import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: "/dataView",
    name: "dataView",
    component: () => import(/* webpackChunkName: "apps" */ "@/views/Application.vue"),
  },
  {
    path: '/typicalExercises',
    name: 'typicalExercises',
    component: () => import(/* webpackChunkName: "apps" */ '@/views/Application.vue'),
  },
  {
    path: '/chatAi',
    name: 'chatAi',
    component: () => import(/* webpackChunkName: "apps" */ '@/views/Application.vue'),
  },
  {
    path: '/cloudNotes',
    name: 'cloudNotes',
    component: () => import(/* webpackChunkName: "apps" */ '@/views/Application.vue'),
  },
  {
    path: '/clientDownload',
    name: 'clientDownload',
    component: () => import(/* webpackChunkName: "apps" */ '@/views/Application.vue'),
  },
  {
    path: '/classPortal',
    name: 'classPortal',
    component: () => import(/* webpackChunkName: "apps" */ '@/views/Application.vue'),
  },
  {
    path: '/applyApp',
    name: 'applyApp',
    component: () => import(/* webpackChunkName: "apps" */ '@/views/Application.vue'),
  },
  {
    path: '/manageSchoolGroup',
    name: 'manageSchoolGroup',
    component: () => import(/* webpackChunkName: "apps" */ '@/views/Application.vue'),
  },
  {
    path: '/manageParentsLessons',
    name: 'manageParentsLessons',
    component: () => import(/* webpackChunkName: "apps" */ '@/views/Application.vue'),
  },
  {
    path: '/parentsLessons',
    name: 'parentsLessons',
    component: () => import(/* webpackChunkName: "apps" */ '@/views/Application.vue'),
  },
  {
    path: '/limitUsageApp',
    name: 'limitUsageApp',
    component: () => import(/* webpackChunkName: "apps" */ '@/views/Application.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(basename),
  routes,
});

export default router;
