export const apiList = {
  //用户相关接口
  user: {
    getAuthToken: "/auth/oauth/token", //登录接口
    getTokenUser: "/auth/token/query-token", //根据token查询用户信息
    getSendSmsCode: "/admin/mobile/sendSmsCode/public/", //发送短信
    getSendEmailCode: "/admin/email/sendEmailCode/public/", //发送邮件
    getVeriSmsCode: "/admin/mobile/checkCode/", //验证手机验证码
    getVeriEmailCode: "/admin/email/checkCode/", //验证邮箱验证码
    getEditPassword: "/admin/user/findPwd", // 修改密码
    getAddRegister: "/admin/user/register/", // 手机号注册帐号
    getAccountInfo: "/admin/user/getById/", // 根据id获取帐号信息
    getEditPerson: "/admin/user/editPerson", // 个人信息完善
    getUpdateInfo: "/admin/user/updateUserInfo", // 修改帐号信息
    getSendSmsCodes: "/admin/mobile/sendSmsCode/", // 发送手机验证码-登录后的
    getUserCheckPwd: "/admin/user/checkPwd", // 验证帐号密码是否正确
    getCancleledReason: "/admin/user/getCancleledReason/", // 获取帐号能否注销和原因
    getCancleled: "/admin/user/cancleled/", // 注销个人帐号
    getRecallCancleled: "/admin/user/recallCancleled/", // 撤回注销个人帐号
    getSignOut: "/auth/token/logout", // 退出登录
    getInstitution: "/admin/institution/getInstitution/", // 根据三级域名获取机构信息
    getUserInfo: "/admin/user/getPersonalInformation/", // 根据id获取个人信息
    getUpdatePersonal: "/admin/user/updatePersonalInformation", // 修改个人信息
    getDictItem: "/dict/dictItem/getDictItem/", // 获取字典项   学段 period；学科 subject；学制 school_length；年级 grade；
    getCommonModeration: "/general/moderation/commonModeration", // 文本内容审核
    getVipHeader: "/vip/membershipHeader/", // 获取会员头像框
    unbindWechat: "/admin/social/unbind", // 解除绑定微信
    bindWechat: "/admin/social/bind", // 绑定微信
    setUserTheme: "/admin/user/setUserTheme", // 设置主题
    getManagerContacts: "/admin/member/getManagerContacts", // 获取机构/学校下管理员\
    getAvatarList:'/admin/user/getAvatarList' // 获取用户头像列表
  },
  todo: {
    getSelect: '/teachingManage/generalAgent/homePageToBeHandled',
    closeNotification: '/teachingManage/generalAgent/pollingForBacklogClose',
    pollingForBacklog: '/teachingManage/generalAgent/pollingForBacklog'
  },
  home: {
    getAreaTree: "/admin/province/areaTree", // 获取省市区树
    getPlanData: "/teachingManage/generalAgent/homeToDoList/", // 目标和任务计划内的数据
    getSpaAfficheList: "/general/roto/getShowList/", // 广告列表
    getMsgNotifyTip: "/msgNotify/msgNotify/tip", // 跑马灯通知
    getMsgNotifyList: "/msgNotify/msgNotify/unReadList", // 消息列表
    getMstListAll: "/msgNotify/msgNotify/findAll", // 所以消息列表
    getAppMusts: "/admin/app/musts", // 必备应用
    getAppGroup: "/admin/app/listGroupByMemberType", // 应用列表
    getAppAlias: "/admin/app/access/alias/", // 查看应用权限
    getGroupList: "/group/top/list/", // 群组列表
    getMyGroupList: "/group/myGroups/", // 我的群组
    getGroupQrCode: "/group/byCode/", // 根据群号查找群组
    getGroupAdd: "/group/member/join", // 加入指定群组
    getGroupAddList: "/group/member/added/list/", // 已加入的群组
    getGroupDel: "/group/member/quit/", // 退出指定群组
    getCourse: "/course/course/lib/get/random", // 随机获取一个学程
    getNoAccesses: "/admin/app/noAccesses", // 应用中心
    getAppInfo: '/admin/app/access/alias/', // 通过别名访问应用信息
    getBatchUpdate: "/msgNotify/msgNotify/batchUpdate", // 消息标记为已读
    getDefaultSchool: "/admin/address/getDefaultSchool", // 默认的学校
    getCurrentWeather: "/tpi/weather/currentWeather", // 获取当前天气
    getDeptInfo: "/admin/dept/deptList", // 获取部门信息
    getClassPhotos: "/photo/photo/getPhotoList", // 获取班级相册
    getAppList: "/admin/app/alias/all", // 获取所有的应用列表
    getAppBubbleByAlias: '/general/app/bubble/getAppBubbleByAlias', // 应用气泡数量获取
    getAppCategoryBubbleByCategories: '/general/app/bubble/getAppCategoryBubbleByCategories', // 应用菜单气泡数量获取
    getAppSort: '/admin/user/app/sort/', // 用户下分类应用排序获取
    sortApp: '/admin/user/app/sort/', // 用户下分类应用排序设置
    getAppUsable: '/admin/app/timeControl/app/usable/', // 应用可用性检查
    getAllTimeRange: '/admin/app/timeControl/query/getAllTimeRange/', // 获取当前用户所有的禁用时间段 
    // 首页数据看板接口
    dateReport: {
      getSchoolList: '/admin/dataAuth/getAuthSchoolList', // 获取学校列表
      getClassTypes: '/dataReport/rector/dateReport/getClassTypes', // 获取班级类型
      getGrades: '/dataReport/rector/dateReport/getGrades', // 获取年级
      getDeptAuthList: '/admin/dataAuth/getDeptAuthList', // 获取年级或者学校 新
      getClass: '/dataReport/teacher/dateReport/getClass', // 获取班级
      // 校长
      rector: {
        getWeekStatic: "/dataReport/rector/dateReport/getWeekStatic", // 本周课堂
        getGradeClassroomWeekStatic: "/dataReport/rector/dateReport/gradeClassroomWeekStatic", // 本周课堂组织情况
      },
      // 年级主任
      grade: {
        getWeekStatic: "/dataReport/director/dateReport/getWeekStatic", // 本周课堂
        getGradeClassroomWeekStatic: "/dataReport/director/dateReport/gradeClassroomWeekStatic", // 本周课堂组织情况
      },
      // 教师
      teacher: {
        getWeekStatic: "/dataReport/teacher/dateReport/getMonthStatic", // 本月课堂
        getGradeClassroomWeekStatic: "/dataReport/teacher/dateReport/submissionDataStatistics", // 本月课堂作品提交数据统计
      },
    }
  },
  resources: {
    getCourseVersion: '/course/warehouse/getVersion/', // 获取学程新旧版本(用于获取新课程详情)
    getLibIdByResId: 'exercise/createCentre/exercisesPaper/getLibIdByResId/', // 根据resId获取libId(习练)
    getCurriculimDetailsById: '/curriculum/creatingCentre/create/detail/', // 【课程】根据id查询课程详情(新课程详情)
    getCourseDetail: '/course/course/lib/tree/', // 学程详情(旧)
    getCurriculumDetail: '/curriculum/curriculum/lib/get/', // 课程详情
    getCurriculumAboutList: "/curriculum/curriculum/lib/about/list", // 相关课程
    getNewCurriculumAboutList: "/curriculum/curriculum/lib/about/list", // 【课程】相关课程(新课程详情)
    getNewUseResource: '/resource/resource/cite/', // 引用资源(新课程详情)
    getNewUseCourse: '/course/warehouse/copyAndCite/', // 引用学程(新课程详情)
    getNewUseExercise: '/exercise/createCentre/exercisesPaper/referenceIn/', // 引用习练(新课程详情)
    getNewUseCurriculum: '/curriculum/curriculum/local/cite', // 【课程】引用课程(新课程详情)
    getExerciseDetail: '/exercise/exercisesLib/get/paper/lib/', // 习练详情
    getToolDetail: '/tpi/rzy/getToolDetail/', // 睿智云工具详情
    getToolUrl: '/tpi/rzy/getToolUrl', // 睿智云工具地址
    getResourceInfo: '/resource/resourceLib/get/', // 获取资源详情
    getPersonResourceInfo: '/resource/resource/get/', // 获取个人资源详情
    collectLesson: '/curriculum/curriculum/coll/', //课程收藏
    useLesson: '/curriculum/curriculum/local/cite/', //课程引用'
    getVipList: "/vip/strategy/forResource", // 获取会员策略
    getNotAuth: "/curriculum/curriculum/local/getNotAuthResource/", // 获取无权限资源列表
  },
  topic: {
    getArticleDetail: '/general/features/'//查看专栏
  },
  activityNews: {
    getNewsDetail: '/activity/information/'
  },
  // 选题页
  choiseExercise: {
    getKnowledgeTree: '/dict/knowledge/tree', // 获取知识点树
    getCatalogTree: '/dict/tutorialDir/getDirByTutorial/', // 获取章节树
    getExerciseListByLibrary: '/exercise/platform/page', // 获取资源库习题列表
    getExerciseListByCenter: '/exercise/personal/creating/page/paper', // 获取创作中心习题列表
  },
  // 组卷页
  practicePaper: {
    addPractice: '/exercise/createCentre/exercisesPaper/add', // 新增习练
    getPracticeDetail: '/exercise/createCentre/exercisesPaper/selectByResId/', // 查询习练详情
    editPractice: '/exercise/createCentre/exercisesPaper/edit', // 编辑习练
    getResourceState: '/res/formContent/getResourceState', // 查询资源列表资源状态
    applyEditExercise: '/exercise/warehouse/', // 习练的习题申请修改
  },
  excersise: {
    getDictItem: "/dict/dictItem/getDictItem/",  // 资源字典选择
    getTutorial: "/dict/tutorial/list", // 获取教材
    getDirByTutorial: "/dict/tutorialDir/getDirByTutorial/", // 获取单元
  },
  tpi: {
    flowIreader: '/tpi/reader/read', // 掌阅记录
    zujuan: '/tpi/xkw/ejt/zujuan/callback',  // 学科网组卷
    syn_status: '/tpi/xkw/ejt/getPaper/syn/status', // 学科网轮询
  },
  boxiao: {
    getAuthSchoolList: '/admin/dataAuth/getAuthSchoolList', //获取当前用户有数据权限的学校列表
    saveCurrentSchool: '/admin/dept/saveCurrentSchool/', //保存用户当前学校信息
  },
  homepage: {
    teacherSpace: '/space/spaVisitorTeacher/getSpaceBaseInfo/',// 教师空间基础信息
    photos:'/space/spaVisitorTeacher/getAlbumPage',// 相册分页数据
    switch:'/space/spaVisitorTeacher/getAlbumLastOrAfter',// 相册上一页下一页
    teach:'/space/spaVisitorTeacher/getTeachingPage',// 教学数据分页
    create:'/space/spaVisitorTeacher/getCreatePage',// 创作数据分页
    events:'/space/spaVisitorTeacher/getActivityPage',// 活动数据分页
    Spatial: '/space/spaVisitor/userSpaceSearch/',// 空间搜索
    guestList:'/space/spaVisitor/getRecentVisitor/',// 获取最近访客列表
    interview:'/space/spaVisitor/addVisitorLog/',// 增加一次访问记录
    setBackground:'/space/spaVisitor/settingBackgroundImage/',// 设置背景图
    background:'/space/spaVisitor/getBackgroundImages',// 获取背景图列表
    scopeOfDisplay:'/space/spaVisitor/settingRadius/',// 设置展示范围
    casual:'/space/spaVisitor/pokeAround',// 随便逛逛
    notePaging:'/space/spaVisitor/getNoteInfo/',// 笔记分页
    giveLike: '/space/spaVisitor/giveLike/',// 点赞
    cardInfo: '/group/member/cardInfo/' // 个人名片
  }
}
