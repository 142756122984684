import "whatwg-fetch"; // fetch polyfill
import "custom-event-polyfill";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import stores from "./stores";
import WujieVue from "wujie-vue3";
import hostMap from "./hostMap";
import credentialsFetch from "./fetch";
// import TDesign from "tdesign-vue-next";
import "tdesign-vue-next/es/style/index.css";
import lifecycles from "./lifecycle";

import { toLogin } from "@/util/util";

import "hxhy-note-editor/dist/index.css";
import hxhyNote from "hxhy-note-editor/dist/hxhy-note-editor.es.js";

// import VConsole from "vconsole";
// const vConsole = new VConsole();

const isProduction = process.env.NODE_ENV === "production";
const { setupApp, preloadApp, bus } = WujieVue;

bus.$on("click", (msg) => window.alert(msg));

bus.$on("toLogin", toLogin);

const degrade =
  window.localStorage.getItem("degrade") === "true" ||
  !window.Proxy ||
  !window.CustomElementRegistry;
/**
 * 大部分业务无需设置 attrs
 * 此处修正 iframe 的 src，是防止github pages csp报错
 * 因为默认是只有 host+port，没有携带路径
 */
const attrs = {};
/**
 * 配置应用，主要是设置默认配置
 * preloadApp、startApp的配置会基于这个配置做覆盖
 */

const setupAppList = [
  { name: "organization", url: "//localhost:7301/" },
  { name: "accountWorkspace", url: "//localhost:7302/" },
  { name: "accountCustomer", url: "//localhost:7303/" },
  { name: "accountPersonal", url: "//localhost:7304/" },
  { name: "permission", url: "//localhost:7305/" },
  { name: "appCentre", url: "//localhost:7306/" },
  { name: "appManage", url: "//localhost:7307/" },
  { name: "sourceLibrary", url: "//localhost:7308/" },
  { name: "groupsSpace", url: "//localhost:7309/" },
  { name: "baseDataManager", url: "//localhost:7310/" },
  { name: "sourceManager", url: "//localhost:7311/" },
  { name: "groupsSpaceConfig", url: "//localhost:7312/" },
  { name: "initializeConfig", url: "//localhost:7313/" },
  { name: "prepareLesson", url: "//localhost:7314/" },
  { name: "myGoods", url: "//localhost:7315/" },
  { name: "vipStrategy", url: "//localhost:7316/" },
  { name: "ordersManager", url: "//localhost:7317/" },
  { name: "editHomeweb", url: "//localhost:7318/" },
  { name: "classPhoto", url: "//localhost:7319/" },
  { name: "weather", url: "//localhost:7320/" },
  { name: "ksResource", url: "//localhost:7321/" },
  { name: "externalChannel", url: "//localhost:7322/" },
  { name: "manageChannel", url: "//localhost:7323/" },
  { name: "manageTopic", url: "//localhost:7324/" },
  { name: "specialTopic", url: "//localhost:7325/" },
  { name: "manageAddress", url: "//localhost:7326/" },
  { name: "chatgpt", url: "//localhost:7327/" },
  { name: "manageActivity", url: "//localhost:7328/" },
  { name: "classSetting", url: "//localhost:7329/" },
  { name: "classInteraction", url: "//localhost:7330/" },
  { name: "classReports", url: "//localhost:7331/" },
  { name: "activity", url: "//localhost:7332/" },
  { name: "learnCentre", url: "//localhost:7333/" },
  { name: "expertLibrary", url: "//localhost:7334/" },
  { name: "evaluate", url: "//localhost:7335/" },
  { name: "learningTools", url: "//localhost:7336/" },
  { name: "certificateEditor", url: "//localhost:7337/" },
  { name: "classManage", url: "//localhost:7338/" },
  { name: "evaluateAgencyManage", url: "//localhost:7341/" },
  { name: "inspirationCentre", url: "//localhost:7342/" },
  { name: "classStuReports", url: "//localhost:7343/" },
  { name: "comprehensiveEvaluate", url: "//localhost:7344/" },
  { name: "orgSourceManage", url: "//localhost:7345/" },
  { name: "parentsManage", url: "//localhost:7346/" },
  { name: "chatManage", url: "//localhost:7347/" },
  { name: "educationalInfo", url: "//localhost:7348/" },
  { name: "chatQa", url: "//localhost:7349/" },
  { name: "todoList", url: "//localhost:7350/" },
  { name: "schoolSourceLib", url: "//localhost:7351/" },
  { name: "classNotice", url: "//localhost:7352/" },
  { name: "spokenTraining", url: "//localhost:7353/" },
  { name: "dataView", url: "//localhost:7354/" },
  { name: "typicalExercises", url: "//localhost:7355/" },
  { name: "chatAi", url: "//localhost:7356/" },
  { name: "cloudNotes", url: "//localhost:7357/" },
  { name: "clientDownload", url: "//localhost:7358/" },
  { name: "classPortal", url: "//localhost:7359/" },
  { name: "applyApp", url: "//localhost:7360/" },
  { name: "manageSchoolGroup", url: "//localhost:7361/" },
  { name: "manageParentsLessons", url: "//localhost:7362/" },
  { name: "parentsLessons", url: "//localhost:7363/" },
  { name: "limitUsageApp", url: "//localhost:7364/" },
];

setupAppList.forEach((item) => {
  setupApp({
    name: item.name,
    url: hostMap(item.url),
    attrs,
    exec: false,
    alive: false,
    plugins: [{ cssExcludes: [] }],
    // 引入了的第三方样式不需要添加credentials
    fetch: (url, options) =>
      url?.includes(hostMap(item.url))
        ? credentialsFetch(url, options)
        : window.fetch(url, options),
    degrade,
    ...lifecycles,
  });
});

createApp(App)
  // .use(TDesign)
  .use(WujieVue)
  // .use(vConsole)
  .use(router)
  .use(stores)
  .use(hxhyNote)
  .mount("#main-app");
